<template>
  <a-card
    :id="'object-image-' + index"
    size="small"
    hoverable
    class="image-card"
    style="width: 300px"
  >
    <a-card-meta>
      <template #title>
        <div class="w-100 d-flex" style="font-weight: 400 !important">
          {{ getFileName(item.path_to_image) }}
        </div>
      </template>
    </a-card-meta>

    <template #cover>
      <img alt="" :src="item.url ? item.url : null" style="width: 100%" />
    </template>

    <template #actions>
      <div class="d-flex justify-content-center">
        <a-tooltip title="Annotate">
          <a-button
            :id="'video-img-edit-btn-' + index"
            @click="openAnnotationModal(index, item)"
            style="flex: 1; border: none; box-shadow: none"
            type="default"
          >
            <template #icon>
              <edit-outlined class="text-primary" />
            </template>
          </a-button>
        </a-tooltip>
        <a-divider type="vertical" style="height: 30px" />
        <a-popconfirm
          cancel-text="No"
          ok-text="Yes"
          title="Are you sure you want to delete this image?"
          @confirm="deleteAnnotation(index, item)"
        >
          <a-button
            :id="'video-img-delete-btn-' + index"
            style="flex: 1; border: none; box-shadow: none"
            type="default"
          >
            <template #icon>
              <delete-outlined class="text-danger" />
            </template>
          </a-button>
        </a-popconfirm>
      </div>
    </template>
  </a-card>
</template>
<script>
import { EditOutlined, DeleteOutlined } from '@ant-design/icons-vue';
export default {
  components: {
    EditOutlined,
    DeleteOutlined,
  },
  props: {
    index: Number,
    item: Object,
    getFileName: Function,
    openAnnotationModal: Function,
    deleteAnnotation: Function,
    isODpipeline: Boolean,
  },
};
</script>
<style>
.image-card {
  padding: 1em;
  padding-bottom: 0;
}

.image-card .ant-card-meta-title {
  white-space: initial;
  text-align: center;
}
</style>
