<template>
  <!-- Annotation Confirm Modal State -->
  <a-modal
    id="annotation-confirm-modal"
    v-model:visible="showAnnotationConfirmModal"
    title="Confirmation"
    :ok-button-props="{ id: 'annotation-confirm-modal-ok-btn' }"
    :cancel-button-props="{
      id: 'annotation-confirm-modal-cancel-btn',
      style: { marginLeft: 'auto' },
    }"
  >
    <template #closeIcon>
      <close-outlined id="annotation-confirm-close-btn" />
    </template>
    <p>
      Are you sure you want to exit labeling? Please select one of the options
      below.
    </p>
    <template #footer>
      <div class="d-flex w-100">
        <span class="ml-auto" />
        <a-button id="close-annotation-btn" @click="handleAnnotationClose">
          Continue without saving
        </a-button>
        <a-button
          id="save-annotation-progress-btn"
          type="primary"
          @click="emitter.emit('save-label')"
        >
          Save progress
        </a-button>
      </div>
    </template>
  </a-modal>

  <!-- Annotation Modal -->
  <a-modal
    id="annotation-modal"
    :visible="showAnnotationModal"
    :title="null"
    width="100%"
    wrap-class-name="full-modal"
    :body-style="{
      padding: '1em',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    }"
    destroy-on-close
  >
    <a-spin v-if="loading" class="m-auto" size="large" />
    <annotation-component
      ref="annotationComponent"
      :key="selectedVideo"
      :loading="loading"
      :video="selectedVideo"
      :task-id="task_id"
      :prev-meta-data="prevMetaData"
      :organization="organization"
      :update-task-record="updateTaskRecord"
      @updateLoading="(val) => (loading = val)"
      @stopLoading="loading = false"
      @onSave="handleLabelling"
      @closeModal="showAnnotationConfirm"
    />
    <template #closeIcon>
      <close-circle-outlined
        id="annotation-cross-btn"
        @click="showAnnotationConfirm"
      />
    </template>
    <template #footer>
      <div class="d-flex w-100">
        <span class="ml-auto" />
        <a-button
          v-if="!loading"
          id="cancel-annotation-btn"
          :disabled="isSavingAnnoation"
          @click="showAnnotationConfirm"
        >
          Cancel
        </a-button>
        <a-button
          v-if="!loading"
          id="save-annotation-btn"
          style="margin-right: 6em"
          :disabled="!isSubstepsChanged"
          type="primary"
          :loading="isSavingAnnoation"
          @click="emitter.emit('save-label')"
        >
          Save
        </a-button>
      </div>
    </template>
  </a-modal>

  <!-- Trimming Modal -->
  <a-modal
    id="timming-modal"
    v-model:visible="showTrimModal"
    :title="null"
    width="100%"
    wrap-class-name="full-modal"
    :body-style="{
      padding: '1em',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    }"
    destroy-on-close
    @cancel="handleTrimModalClose"
  >
    <template #closeIcon>
      <close-outlined id="trimming-modal-close-btn" />
    </template>
    <a-spin v-if="loading" class="m-auto" size="large" />
    <trimming-component
      ref="trimming"
      :key="videoToTrim"
      :loading="loading"
      :video="videoToTrim"
      :task-id="task_id"
      :task-name="taskName"
      @stopLoading="loading = false"
      @updateTrimProgress="(progress) => (trimProgress = progress)"
      @updateTrimLoading="(isLoading) => (isTrimming = isLoading)"
      @closeModal="finishTrimming"
    />

    <template #footer>
      <a-row class="w-100 d-flex align-items-center">
        <!-- <a-col span="21"
          ><a-progress :percent="trimProgress" v-if="isTrimming"
        /></a-col> -->
        <a-col span="2" class="ml-auto mr-4">
          <a-button
            v-if="!loading"
            id="trim-video-btn"
            type="primary"
            :loading="isTrimming"
            @click="emitter.emit('trim-video')"
          >
            Trim Video
          </a-button>
        </a-col>
      </a-row>
    </template>
  </a-modal>

  <!--  Video Modal V2 -->
  <a-modal
    id="video-modal"
    v-model:visible="showVideoModal"
    centered
    :title="videoToPlay?.fileName"
    @cancel="handleVideoModalClose"
  >
    <template #closeIcon>
      <close-outlined id="video-modal-close-btn" />
    </template>
    <template #footer>
      <a-descriptions title="Details" class="text-start">
        <a-descriptions-item id="video-filename-tag" label="Name" span="3">
          <a-tag color="blue">
            {{ videoToPlay?.fileName }}
          </a-tag>
        </a-descriptions-item>
        <a-descriptions-item id="video-resolution-tag" label="Resolution">
          <a-tag color="blue">
            {{ videoToPlay?.resolution }}
          </a-tag>
        </a-descriptions-item>
        <a-descriptions-item id="video-duration-tag" label="Duration" span="2">
          <a-tag color="blue">
            {{ videoToPlay?.duration }}
          </a-tag>
        </a-descriptions-item>
        <a-descriptions-item id="video-fps-tag" label="FPS">
          <a-tag color="blue">
            {{ videoToPlay?.fps }}
          </a-tag>
        </a-descriptions-item>
        <a-descriptions-item id="video-labelled%--tag" label="Labelled">
          <a-tag color="blue"> {{ videoToPlay?.labelled.toFixed(1) }}% </a-tag>
        </a-descriptions-item>
      </a-descriptions>
    </template>

    <div
      v-if="isfetchingVideoUrl"
      class="w-100 d-flex"
      style="height: 350px !important"
    >
      <a-spin class="m-auto" size="large" />
    </div>
    <video
      v-else
      id="video-to-play-elem"
      class="w-100"
      controls
      :src="videoToPlay?.fileURL"
    />
  </a-modal>

  <!-- Org Tags Modal -->
  <a-modal
    id="add-tag-modal"
    v-model:visible="orgTagsModal"
    title="Tags"
    centered
    :footer="null"
  >
    <template #closeIcon>
      <close-outlined id="add-tag-modal-close-btn" />
    </template>
    <a-input-search
      id="tag-name-input"
      v-model:value="tagName"
      name="tagName"
      placeholder="Tag Name"
      @search="handleAddOrgTag"
    >
      <template #enterButton>
        <a-button
          id="add-tag-btn"
          type="primary"
          :disabled="!tagName"
          :loading="isCreatingObject"
        >
          <plus-outlined />
          Add
        </a-button>
      </template>
    </a-input-search>

    <div class="objects-container">
      <a-list
        id="tags-list"
        size="small"
        :loading="isLoadingTag"
        item-layout="horizontal"
        :data-source="orgTags"
      >
        <template #renderItem="{ item, index: tagIndex }">
          <a-list-item>
            <a-list-item-meta
              :id="'tag-' + tagIndex"
              :title="item.tag_name"
              :description="item.descript"
            />
          </a-list-item>
        </template>
      </a-list>
    </div>
  </a-modal>

  <!-- Comments Modal -->
  <a-modal
    id="comments-modal"
    v-model:visible="commentsModal"
    title="Comments"
    :footer="null"
    centered
  >
    <template #closeIcon>
      <close-outlined id="comments-modal-close-btn" />
    </template>
    <a-list
      id="comments-list"
      :data-source="comments"
      item-layout="horizontal"
      style="height: 40vh; overflow: auto"
      size="small"
    >
      <template #renderItem="{ item, index: commentIndex }">
        <a-list-item :id="'comment-' + commentIndex">
          <a-comment :author="item.user_id.username" :content="item.comment">
            <template v-if="isAssociateUserComment(item.user_id)" #actions>
              <delete-outlined
                :id="'delete-comment-btn-' + commentIndex"
                class="text-danger"
                @click="deleteComment(item)"
              />
            </template>
          </a-comment>
        </a-list-item>
      </template>
    </a-list>
    <a-comment>
      <template #content>
        <a-form-item>
          <a-textarea
            id="label-new-comment-input"
            v-model:value="newComment"
            :rows="4"
          />
        </a-form-item>
        <a-form-item>
          <a-button
            id="add-comment-btn"
            html-type="submit"
            :loading="addingComment"
            type="primary"
            @click="handleAddComment"
          >
            Add Comment
          </a-button>
        </a-form-item>
      </template>
    </a-comment>
  </a-modal>

  <!-- Tags Modals -->
  <a-modal
    id="tags-modal"
    v-model:visible="tagsModal"
    title="Tags"
    :footer="null"
    centered
  >
    <template #closeIcon>
      <close-outlined id="tags-modal-close-btn" />
    </template>
    <div style="height: 30vh">
      <a-select
        v-model:value="currentSelectedTag"
        id="select-tag-input"
        show-search
        mode="multiple"
        placeholder="Select Tag"
        :disabled="loadingTags"
        :loading="loadingTags"
        :options="tagOptions"
        :filter-option="filterOption"
        class="w-100"
        dropdown-class-name="select-tag-input-class"
        @select="handleAddTag"
        @deselect="handleTagRemove"
      />
    </div>
  </a-modal>

  <a-row :gutter="[16, 16]" class="py-4 px-2 p-md-4">
    <a-col :xs="20" :sm="22" :md="8" :lg="8">
      <a-select
        id="label-data-select-task"
        ref="label_task_select"
        v-model:value="task_id"
        class="w-100"
        show-search
        placeholder="Select Task"
        :options="taskOptions"
        :filter-option="filterOption"
        :loading="isFetchingTasks"
        @change="() => $refs.label_task_select.blur()"
      />
    </a-col>
    <a-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="13"
      :xl="11"
      class="d-md-flex d-sm-none d-none ml-auto"
    >
      <a-space class="ml-auto">
        <a-button id="add-tags-button" type="primary" @click="openTagsModal">
          <template #icon>
            <plus-outlined />
          </template>
          Add Tags
        </a-button>
      </a-space>
    </a-col>
    <!-- mobile screen -->
    <a-col :xs="4" :sm="2" class="d-block d-sm-block d-md-none">
      <div class="demo-dropdown-wrap">
        <a-dropdown-button
          :trigger="['click']"
          placement="bottomRight"
          class="float-right"
        >
          <template #overlay>
            <a-menu :selected-keys="currentItem">
              <a-menu-item key="tags">
                <a-button type="primary" class="w-100" @click="openTagsModal">
                  <template #icon>
                    <plus-outlined />
                  </template>
                  Add Tags
                </a-button>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown-button>
      </div>
    </a-col>

    <a-col :span="24">
      <a-tabs id="label-data-tabs" v-model:activeKey="activeTab">
        <a-tab-pane id="label-data-video-tab" key="videos">
          <template #tab>
            <span>
              <play-square-outlined />
              Videos
            </span>
          </template>
          <video-list
            v-show="currentTab === 0"
            :task-id="task_id"
            :video_list="video_list"
            :videos-count="videosCount"
            :sort-by="sortBy"
            :sort-order="sortOrder"
            :loading="loadingVideos"
            :is-meta-files-being-updated="isTaskBeingUpdated"
            :isTask3D="isTask3D"
            @handlePlay="handlePlay"
            @updateVideo="updateVideo"
            @annotateVideo="handleAnnotate"
            @handleExportMetaFile="handleExportMetaFile"
            @trimVideo="handleTrim"
            @deleteVideo="handleDeleteVideo"
            @updateTaskRecord="updateTaskRecord"
            @sortList="handleSortList"
            @pageChange="handlePageChange"
            @showCommentsModal="showCommentsModal"
            @showTagsModal="showTagsModal"
          />
        </a-tab-pane>
        <a-tab-pane id="label-data-mark-obj-tab" key="marking">
          <template #tab>
            <span>
              <picture-outlined />
              Mark Objects
            </span>
          </template>
          <annotation-list
            :image-list="imagesForAnnotation"
            :total-results="totalImagesForAnnotation"
            :task-id="task_id"
            :loading-images="loadingImages"
            :current-image-index="currentIndexForImage"
            :current-image-page="currentPageForImage"
            @changeImagesPage="handlePageChangeImages"
            @changeImageIndex="updateImageIndex"
            @changeImageAnnotationPath="updateImageAnnotationPath"
            @cacheImage="cacheImage"
            @loadAnnotationImages="loadImagesForAnnotation"
          />
        </a-tab-pane>
      </a-tabs>
    </a-col>
  </a-row>
</template>

<script>
import httpClient from 'src/service/httpClient.js';
import VideoList from './Videos.vue';
import { mapActions, mapGetters } from 'vuex';
import AnnotationComponent from '../annotation/Annotation.vue';
import TrimmingComponent from '../import/Trimming/Trimming.vue';
import getTimeDuration from '../../../shared/Helpers/getDuration';
import AnnotationList from './AnnotationList.vue';
import {
  PlusOutlined,
  CloseCircleOutlined,
  PlaySquareOutlined,
  PictureOutlined,
  DeleteOutlined,
  CloseOutlined,
} from '@ant-design/icons-vue';
import noVideo from 'src/assets/img/no-video.png';
import downloadFile from '../../../shared/Helpers/downloadFile';
import VideoService from '../../../../services/videos';
import spaceMixin from 'src/mixins/handleSpace';
import longRunningTask from 'src/mixins/longRunningTask';
import { getSortedTask } from 'src/utils/task';

export default {
  components: {
    AnnotationList,
    VideoList,
    AnnotationComponent,
    TrimmingComponent,
    DeleteOutlined,
    PlusOutlined,
    CloseCircleOutlined,
    PlaySquareOutlined,
    PictureOutlined,
    CloseOutlined,
  },
  mixins: [spaceMixin, longRunningTask],
  inject: ['toast'],
  setup() {
    const userId = localStorage.getItem('id');
    const userName = localStorage.getItem('name');
    return { userId, userName };
  },

  data() {
    return {
      currentSelectedTag: [],
      tagOptions: [],
      loadingTags: false,
      tags: [],
      activeCommentTagTab: '1',
      currentEntityName: '',
      currentRecordEntityId: null,
      addingComment: false,
      newComment: '',
      comments: [],
      commentsModal: false,
      task_list: [],
      isLoadingTasks: false,
      options: [],
      task_id: undefined,
      taskName: null,
      taskDetails: '',
      isTaskDetailsLoading: false,
      taskJson: null,
      show: false,
      showUploadModal: false,
      selectedVideo: null,
      videoToTrim: null,
      videoToPlay: null,
      loading: true,
      loadingVideos: false,
      currentTab: 0,
      sortBy: 'id',
      sortOrder: 'ascend',
      isConfirm: null,
      openDeleteVideoDialog: false,
      videoToDelete: null,
      prevMetaData: null,
      //===============  Annotation ============================
      showAnnotationModal: false,
      showAnnotationConfirmModal: false,
      //===============  Trimming ============================
      showTrimModal: false,
      trimProgress: 0,
      isTrimming: false,
      openObjectAnnotateDialog: false,
      isObjectAnnotationModalLoading: false,
      //===========================================
      currentPage: 1,
      searchFilterValue: '',
      searchQuery: {},
      video_list: [],
      videosCount: 0,
      showVideoModal: false,
      isfetchingVideoUrl: false,
      hasFetchCalled: false,
      // ==================
      isNext: true,
      targetNode: '',
      //===========================================
      loadingAnnotationObjects: false,
      objectName: '',
      isCreatingObject: false,
      isObjectStatic: false,
      showObjectModal: false,
      annotationObjects: [],
      optionsForObjects: [],
      objectsPreferences: [
        {
          label: 'Required',
          value: '-',
        },
        {
          label: 'Optional',
          value: '+',
        },
        {
          label: 'Branched',
          value: '*',
        },
      ],

      //===========================================
      showruleEngineModal: false,
      selectWidth: 200,
      stepsList: [],
      startMarkerForSteps: {},
      endMarkerForSteps: {},
      canFetchRuleEnginejson: false,
      ruleJsonfileName: 'rule-engine.json',
      //===========================================
      log: console.log,
      window: window,
      //===========================================
      activeTab: 'videos',
      imagesForAnnotation: [],
      totalImagesForAnnotation: 0,
      currentPageForImage: 1,
      currentIndexForImage: -1,
      loadingImages: false,
      top: 170,
      noVideo,
      // =================== Tags
      tagsModal: false,
      orgTagsModal: false,
      tagName: '',
      tagDesc: '',
      isLoadingTag: false,
      orgTags: [],
      currentItem: ['tags'],
      ruleInputValue: '',
      rulesList: [],
      objectList: [],
      isTask3D:false
    };
  },

  computed: {
    ...mapGetters([
      'selectedTask',
      'isTourRunning',
      'role',
      'organization',
      'isSavingAnnoation',
      'user',
      'isSubstepsChanged',
      'allTasks',
      'isFetchingTasks',
    ]),
    taskOptions() {
      return getSortedTask(this.allTasks);
    },
  },

  watch: {
    task_id(value) {
      if (!value) return;
      this.setSelectedTask(value);
      this.searchQuery = { ...this.searchQuery, task: value, page: this.currentPage };
      this.setLongRunningTaskInterval();
      this.sortBy = 'id';
      this.fetchVideos(value);
      this.fetchTaskDetails(value);
      this.set3dCheck(value)
      // this.getTaskDetails(value);
      this.onChangeTask(value);
      this.getTaskObjects(value);
      this.fetchAssociateBarcodes(value);
      this.loadImagesForAnnotation(this.task_id);
      this.currentIndexForImage = -1;
      this.setSelectedLanguage('English');
    },
    allTasks() {
      this.onChangeTask(this.task_id);
    },

    isConfirm(value) {
      if (value === null) return;
      if (value) {
        this.$refs.trim.toggleModal();
      }
      this.isConfirm = null;
      this.$refs.dialog.toggleModal();
    },

    stepsList(value) {
      const temp = {};
      value.forEach((li) => {
        temp[li.name] = [];
      });
      this.startMarkerForSteps = temp;
      this.endMarkerForSteps = temp;
      if (this.canFetchRuleEnginejson) this.fetchRuleEngineJson();
    },

    annotationObjects(value) {
      this.optionsForObjects = value.map(({ name }) => ({
        value: name,
        label: name,
        children: [...this.objectsPreferences],
      }));
    },

    video_list(videos) {
      if (this.hasFetchCalled) return;
      const videosWithoutThumbnail = videos.filter((v) => !v.thumbnail_path);
      this.fetchAndUpdateThumbnails(videosWithoutThumbnail);
    },

    currentPage(value) {
      this.searchQuery = { ...this.searchQuery, page: value };
    },

    activeTab(value) {
      this.searchQuery = { ...this.searchQuery, tab: value };

      if (value === 'marking' && this.task_id) {
        this.loadImagesForAnnotation(this.task_id);
      }
    },

    searchFilterValue(searchValue) {
      this.searchQuery = { ...this.searchQuery, search: searchValue };
    },

    searchQuery(query) {
      this.updateQueryParams(query);
    },
  },

  created() {

    this.getAllTasks();
    this.populateParams();
  },

  beforeUnmount() {
    this.clearTaskDetails();
  },

  methods: {
    ...mapActions([
      'setTaskId',
      'stopTour',
      'setSelectedTask',
      'setPrevMetaData',
      'setTaskObjectList',
      'getAllTasks',
      'fetchTaskDetails',
      'setSelectedLanguage',
      'clearTaskDetails',
      'fetchAssociateBarcodes',
    ]),

    openTagsModal() {
      this.orgTagsModal = true;
      this.fetchOrgTags();
    },

    isAssociateUserComment(user_data) {
      return user_data.id == this.userId && user_data.username == this.userName
        ? true
        : false;
    },

    async handleTagRemove(tagId) {
      this.loadingTags = true;
      const { entity_id } = this.selectedVideo;
      const entityToDelete = this.tags.find(
        (t) => t.entity_id === entity_id && t.tag_name.id === tagId
      );
      if (!entityToDelete) return;

      const res = await httpClient.delete(
        `organization/entity/tags/`,
        entityToDelete.id,
        false,
        false,
        false
      );
      if (res === 'error') {
        console.log('error occured while removing tag');
      }

      this.loadingTags = false;
    },

    async fetchOrgTags() {
      this.isLoadingTag = true;
      const res = await httpClient.get('organization/task_record/tags', false);
      this.orgTags = res;
      this.isLoadingTag = false;
    },

    async deleteComment(comment) {
      this.comments = this.comments.filter((t) => t.id !== comment.id);
      const [error, _] = await VideoService.deleteComment(comment.id);
      if (error) {
        console.log({ error });
        this.toast.error('Error in deleting!');
        return;
      }

      VideoService.decreaseCommentCount(this.selectedVideo);
      const { fileName, no_of_comments } = this.selectedVideo;
      this.updateVideo(fileName, { no_of_comments: no_of_comments - 1 });
      this.selectedVideo = {
        ...this.selectedVideo,
        no_of_comments: no_of_comments - 1,
      };
    },

    async handleAddOrgTag() {
      this.isLoadingTag = true;
      const payload = {
        tag_name: this.tagName,
        descript: this.tagDesc || 'default',
        organization: this.organization,
      };
      const res = await httpClient.post(
        'organization/task_record/tags',
        payload,
        false,
        false,
        false
      );
      this.orgTags = [...this.orgTags, res];
      this.isLoadingTag = false;
      this.tagName = '';
      this.tagDesc = '';
    },

    async createEntity() {
      const payload = {
        name: this.selectedVideo.fileName,
        organization: this.organization,
      };
      return await VideoService.createEntityForVideo(payload);
    },

    async createComment() {
      const paylaod = {
        comment: this.newComment,
        entity_id: this.selectedVideo.entity_id,
        user_id: this.userId,
      };
      return await VideoService.addNewComment(paylaod);
    },

    async handleAddComment() {
      this.addingComment = true;

      if (!this.selectedVideo.entity_id) {
        const res = await this.createEntity();
        const temp = { ...this.selectedVideo };
        temp['entity_id'] = res.id;
        this.selectedVideo = temp;
      }

      const comment_res = await this.createComment();
      comment_res['user_id'] = { id: comment_res.user_id, username: this.user };
      this.comments = [...this.comments, comment_res];

      const { entity_id, no_of_comments } = this.selectedVideo;
      this.updateTaskRecord(
        { entity_id: entity_id, no_of_comments: no_of_comments + 1 },
        this.selectedVideo,
        false
      );
      this.selectedVideo = {
        ...this.selectedVideo,
        no_of_comments: no_of_comments + 1,
      };
      this.addingComment = false;
      this.newComment = '';
    },

    async createTag(tagId) {
      const paylaod = {
        tag_name: tagId,
        entity_id: this.selectedVideo.entity_id,
        user_id: this.userId,
      };

      return await httpClient.post(
        `organization/entity/tags`,
        paylaod,
        false,
        false,
        false
      );
    },

    async handleAddTag(tagId) {
      this.loadingTags = true;
      if (!this.selectedVideo.entity_id) {
        const res = await this.createEntity();
        const temp = { ...this.selectedVideo };
        temp['entity_id'] = res.id;
        this.selectedVideo = temp;
        this.updateTaskRecord(
          { entity_id: this.selectedVideo.entity_id },
          this.selectedVideo,
          false
        );
      }

      const tag_res = await this.createTag(tagId);
      tag_res['tag_name'] = this.orgTags.find(
        (ot) => ot.id === tag_res.tag_name
      );

      this.tags = [...this.tags, tag_res];
      this.loadingTags = false;
    },

    cacheImage(img) {
      this.imagesForAnnotation[this.currentIndexForImage].loadedImage = img;
    },

    showTagsModal(data) {
      this.selectedVideo = data.taskRecord;
      this.orgTags = data.orgTags;
      this.tags = data.entityTag;
      this.currentSelectedTag = data.entityTag.map((e) => e.tag_name.id);
      this.tagOptions = data.orgTags.map((el) => ({
        value: el.id,
        label: el.tag_name,
      }));
      this.tagsModal = true;
    },

    showCommentsModal(data) {
      this.selectedVideo = data.taskRecord;
      this.comments = data.comments;
      this.commentsModal = true;
    },

    generateNextAndPrevIndices(index) {
      let indexList = [];
      for (let itr = index - 10; itr !== index; itr++) {
        if (itr >= 0) indexList.push(itr);
      }
      for (
        let itr = index + 1;
        itr < this.imagesForAnnotation.length && itr <= index + 10;
        ++itr
      )
        indexList.push(itr);
      return indexList;
    },

    async updateImageIndex(index) {
      this.currentIndexForImage = index;
      const iterations = this.generateNextAndPrevIndices(index);
      for await (const itr of iterations) {
        if (!this.imagesForAnnotation[itr].loadedImage) {
          const imageUrl = await this.getImageUrl(
            this.organization + '-training',
            this.imagesForAnnotation[itr].path_to_image
          );

          this.imagesForAnnotation[
            itr
          ].loadedImage = await this.getImageFromSrc(imageUrl);
        }
      }
    },

    getImageFromSrc(src) {
      return new Promise(async (resolve, reject) => {
        const image = new window.Image();
        image.src = src;
        image.onload = () => resolve(image);
      });
    },

    async getImageUrl(bucket, path_to_image) {
      const { presigned_url } = await httpClient.post(
        'generic/generate_new_url/',
        { bucket_name: bucket, object_path: path_to_image },
        false,
        false,
        false
      );
      return presigned_url;
    },

    updateImageAnnotationPath(image) {
      this.imagesForAnnotation[this.currentIndexForImage].path_to_annotations =
        image.path_to_annotations;
    },

    finishTrimming() {
      this.showTrimModal = false;
      this.fetchVideos(this.task_id);
      this.trimProgress = 0;
      this.isTrimming = false;
    },

    closeObjectAnnotationModal() {
      this.openObjectAnnotateDialog = false;
    },

    openObjectAnnotationModal(record) {
      this.openObjectAnnotateDialog = true;
      this.selectedVideo = { ...record };
    },

    populateParams() {
      const { task, tab, page } = this.$route.query;
      this.currentPage = page;
      const taskId = task || this.selectedTask;
      this.activeTab = tab || 'videos';
      this.task_id = taskId && Number(taskId);
    },

    async fetchAndUpdateThumbnails(videosWithoutThumbnail) {
      this.hasFetchCalled = true;
      for await (const video of videosWithoutThumbnail) {
        const url = await this.getVideoUrl(video);
        const { bucket, filePath: path } = this.getVideoS3Details(video);
        const { thumbnail_path } = await this.getThumbnailPath(
          url,
          bucket,
          path
        );
        if (thumbnail_path)
          await this.updateTaskRecord({ thumbnail_path }, video, false);
        else {
          this.updateVideo(video.fileName, { noVideo });
        }
      }
      this.hasFetchCalled = false;
    },

    async getThumbnailPath(file_url, bucket_name, file_path) {
      const payload = {
        file_url,
        bucket_name,
        file_path,
      };
      return await httpClient.post(
        'generic/create_thumbnail/',
        payload,
        false,
        false,
        false
      );
    },

    markerContainerWidth(marker) {
      let count = 0;
      Object.values(this[marker]).forEach((el) => {
        count = el.length > count ? el.length : count;
      });
      return count * this.selectWidth + 'px';
    },

    async getTaskObjects(taskId) {
      const res = await httpClient.get(
        `organization/task/list_task_objects/${taskId}/`
      );
      if (res === 'error') return;

      this.annotationObjects = res;
      this.setTaskObjectList(res);
    },

    addObjectToStep(stepName, position) {
      const temp = { ...this[position] };
      if (temp[stepName] && temp[stepName].length === 0) {
        temp[stepName] = [undefined, undefined];
      } else if (temp[stepName]) {
        temp[stepName].push(undefined);
      }
      this[position] = temp;
    },

    removeObjectFromStep(stepName, objectIndex, position) {
      const temp = { ...this[position] };
      if (temp[stepName].length === 2) {
        temp[stepName] = [];
      } else {
        temp[stepName] = temp[stepName].filter(
          (_, index) => index !== objectIndex
        );
      }
      this[position] = temp;
    },

    handleChangeObject(value, stepName, objectIndex, position) {
      const temp = { ...this[position] };
      temp[stepName][objectIndex] = value;
      this[position] = temp;
    },

    openruleEngineModal() {
      this.showruleEngineModal = true;
      this.fetchRuleEngineJson();
    },

    async fetchRuleJsonUrl() {
      const bucket = `${this.organization}-training`;
      const filePath = `${this.taskName}/Detector/${this.ruleJsonfileName}`;

      return await this.fetchPresignedUrl(bucket, filePath);
    },

    // async fetchRuleEngineJson() {
    //   if (this.isTaskDetailsLoading) {
    //     this.canFetchRuleEnginejson = true;
    //     return;
    //   }
    //   this.canFetchRuleEnginejson = false;
    //   this.isTaskDetailsLoading = true;

    //   const presigned_url = await this.fetchRuleJsonUrl();

    //   if (presigned_url === 'error') {
    //     this.isTaskDetailsLoading = false;
    //     return;
    //   }

    //   try {
    //     const { data } = await axios.get(presigned_url);

    //     this.updateRuleEngineJson(data);
    //   } catch (e) {}
    //   this.isTaskDetailsLoading = false;
    // },

    // updateRuleEngineJson(prevJson) {
    //   const startObj = { ...this.startMarkerForSteps };
    //   const endObj = { ...this.endMarkerForSteps };

    //   this.stepsList
    //     .filter((s) => s.type !== 'step')
    //     .forEach((step) => {
    //       startObj[step.name] = this.getObjsWithOps(prevJson, step, 'start');
    //       endObj[step.name] = this.getObjsWithOps(prevJson, step, 'end');
    //     });

    //   this.startMarkerForSteps = startObj;
    //   this.endMarkerForSteps = endObj;
    // },

    getObjsWithOps(prevJson, step, marker) {
      return prevJson[step.name][marker].map((el) => [el.substr(1), el[0]]);
    },

    getMarkedObjects(step, markerType) {
      return this[markerType][step.name].map(([obj, pref]) => pref + obj);
    },

    // getMarkedObjectsJson() {
    //   const json = {};
    //   this.stepsList
    //     .filter((s) => s.type !== 'step')
    //     .forEach((s) => {
    //       json[s.name] = {
    //         start: this.getMarkedObjects(s, 'startMarkerForSteps'),
    //         end: this.getMarkedObjects(s, 'endMarkerForSteps'),
    //       };
    //     });
    //   return JSON.stringify(json);
    // },

    // async saveStepObjectMapping() {
    //   this.isTaskDetailsLoading = true;
    //   const json = this.getMarkedObjectsJson();
    //   const payload = this.getRuleEnginePayload(json);
    //   const res = await httpClient.post(
    //     'generic/file_upload/',
    //     payload,
    //     false,
    //     false,
    //     false
    //   );
    //   this.isTaskDetailsLoading = false;
    //   if (res === 'error') {
    //     this.toast.error('Error occured!');
    //     return;
    //   }
    //   this.showruleEngineModal = false;
    //   this.toast.success('Details saved successfully!');
    // },

    getRuleEnginePayload(json) {
      var formData = new FormData();
      const bucket = `${this.organization}-training`;
      const filePath = `${this.taskName}/Detector/${this.ruleJsonfileName}`;
      var blob = new Blob([json], { type: 'text/json;charset=utf-8' });
      formData.append('file', blob, this.ruleJsonfileName);
      formData.append('file_path', filePath);
      formData.append('bucket', bucket);
      return formData;
    },

    openAnnotationObjectModal() {
      this.showObjectModal = true;
    },

    getObjectPayload() {
      return {
        name: this.objectName,
        is_static: this.isObjectStatic,
        task: this.task_id,
      };
    },

    async handleObjectAdd() {
      const payload = this.getObjectPayload();

      this.isCreatingObject = true;
      const res = await httpClient.post(
        'organization/task/task_object/',
        payload
      );
      this.isCreatingObject = false;

      if (res === 'error') return;
      this.annotationObjects = [...this.annotationObjects, res];
      this.setTaskObjectList(this.annotationObjects);
      this.objectName = '';
      this.isObjectStatic = false;
    },

    async removeObject(id) {
      this.loadingAnnotationObjects = true;
      const res = await httpClient.delete(
        'organization/task/task_object/',
        id + '/',
        false
      );
      this.loadingAnnotationObjects = false;
      if (res === 'error') return;

      const temp = [...this.annotationObjects];
      this.annotationObjects = temp.filter((object) => object.id !== id);
      this.setTaskObjectList(this.annotationObjects);
    },

    checkIfNodeExists(stepIndex) {
      if (!this.steps[stepIndex]) return;
      const { target } = this.steps[stepIndex];
      const node = document.querySelector(target);
      this.targetNode = target;
      this.isNext = !!node;
    },

    taskCompUpdate() {
      if (this.targetNode) {
        this.isNext = !!document.querySelector(this.targetNode);
      }
    },

    updateTaskJsonEmit(taskJson) {
      this.taskJson = taskJson;
    },

    onChangeTask(task_id) {
      this.taskName = this.allTasks.find((el) => el.id === task_id)?.taskName;
      // this.taskName = this.task_list.find((el) => el.id === task_id)?.taskName;
      this.setTaskId(task_id);
    },

    changeTab(current) {
      if (!this.task_id) return;
      this.currentTab = current;
    },

    getUrl(taskId, page, search) {
      let url = 'organization/task/upload/' + taskId + '/?';
      const orderMapping =
        {
          ascend: '',
          descend: '-',
        }[this.sortOrder] || '';

      url = page > 1 ? `${url}page=${page}&` : url;
      if (search) {
        url += `isUploadedVideo=true&search=${search}`;
        return url;
      }
      const orderBy = `${orderMapping}${this.sortBy}`;
      url += `ordering=${orderBy}`;
      return url;
    },

    async fetchVideos(taskId) {
      let url = this.getUrl(taskId, this.currentPage, this.searchFilterValue);
      this.loadingVideos = true;
      const res = await httpClient.getData(url, false);
      this.loadingVideos = false;
      if (res === 'error') {
        this.searchQuery = { ...this.searchQuery, task: this.task_id, page: 1 };
        this.video_list = [];
        return;
      }
      this.setVideosList(res);
    },

    setVideosList(res) {
      this.video_list = res.results;
      this.videosCount = res.count || 0;
    },

    updateQueryParams(query) {
      query = {
        ...this.$route.query,
        ...query,
      };
      this.$router.replace({
        name: this.$route.name,
        query,
      });
    },

    handlePageChange({ page, filterValue = this.searchFilterValue }) {
      this.currentPage = page;
      this.searchFilterValue = filterValue;
      this.fetchVideos(this.task_id);

    },

    handleSortList({ field, order }) {
      this.sortBy = order ? field : '';
      this.sortOrder = order;
      this.fetchVideos(this.task_id);
    },

    getSortOrder(a, b, field, sortOrder) {
      let orders = {};

      if (field === 'duration') {
        orders = {
          asc: () => getTimeDuration(a[field]) - getTimeDuration(b[field]),
          des: () => getTimeDuration(b[field]) - getTimeDuration(a[field]),
        };
      } else if (field === 'fileName') {
        orders = {
          asc: () => -1,
          des: () => -1,
        };
      } else {
        orders = {
          asc: () => a[field] - b[field],
          des: () => b[field] - a[field],
        };
      }
      return orders[sortOrder]();
    },

    updateVideo(videoName, updatedObj) {
      const temp = [...this.video_list];
      const index = temp.findIndex((v) => v.fileName === videoName);
      if (temp[index]) {
        temp[index] = {
          ...temp[index],
          ...updatedObj,
        };
      }
      this.video_list = temp;
    },

    getVideoS3Details(video) {
      const { fileName } = video;
      const bucket = `${this.organization}-training`;
      const filePath = `${this.taskName}/Videos/${fileName}`;
      return { bucket, filePath };
    },

    async fetchPresignedUrl(bucket_name, object_path) {
      const payload = {
        bucket_name,
        object_path,
      };
      return new Promise(async (resolve, _) => {
        const res = await httpClient.post(
          'generic/generate_new_url/',
          payload,
          false,
          false,
          false
        );
        resolve(res.presigned_url || res);
      });
    },

    async getVideoUrl(video) {
      const { bucket, filePath } = this.getVideoS3Details(video);
      return await this.fetchPresignedUrl(bucket, filePath);
    },

    async handlePlay(video) {
      if (!video?.fileName || video.noVideo) {
        this.toast.error(`${video.fileName} is not found!`);
        return;
      }

      this.showVideoModal = true;
      if (!video.fileURL) {
        this.isfetchingVideoUrl = true;
        const presignedUrl = await this.getVideoUrl(video);
        this.isfetchingVideoUrl = false;
        if (presignedUrl === 'error') {
          this.toast.error('Error occured while fetching url!');
          return;
        }
        video = { ...video, fileURL: presignedUrl };
      }
      this.videoToPlay = { ...video };
    },

    handleVideoModalClose() {
      this.updateVideo(this.videoToPlay.fileName, this.videoToPlay);
    },

    openUploadingModal() {
      this.showUploadModal = true;
      setTimeout(this.$refs.vTour.nextStep, 500);
    },

    // async getTaskDetails(task_id) {
    //   this.isTaskDetailsLoading = true;
    //   const data = await httpClient.getData(
    //     'organization/task/' + task_id + '/'
    //   );
    //   this.isTaskDetailsLoading = false;

    //   if (data === 'error') return;
    //   const { task_detail, taskJson } = data;
    //   this.taskDetails = task_detail;
    //   this.taskJson = taskJson;
    //   this.stepsList = parseTaskList(task_detail);
    // },

    async handleAnnotate(video) {
      if (!video.fileURL) {
        const presignedUrl = await this.getVideoUrl(video);
        video = { ...video, fileURL: presignedUrl };
      }
      this.selectedVideo = { ...video };
      this.getPrevMetaData();
    },

    getPreMetaDataUrl() {
      const organization = this.role === 'admin' ? null : this.organization;
      const url = `organization/task/upload/retrieve_metadata?org=${organization}&task_name=${
        this.taskName
      }&video_name=${this.selectedVideo?.fileName.replaceAll(
        '+',
        '%2B'
      )}&task_id=${this.task_id}`;
      return url;
    },

    async getPrevMetaData() {
      if (!this.selectedVideo) return;
      const url = this.getPreMetaDataUrl();
      const { fileName } = this.selectedVideo;

      this.updateVideo(fileName, { loadingAnnotation: true });
      let response = await httpClient.getData(url, false);
      this.updateVideo(fileName, { loadingAnnotation: false });

      if (response === 403)
        this.toast.info('Please wait 10 seconds to label the video again');
      if (['error', 403].includes(response)) return;
      this.showAnnotationModal = true;
      if (response.error === 'NoSuchKey') {
        this.setPrevMetaData(null);
      } else {
        this.setMetaData(response);
      }
    },

    showAnnotationConfirm() {
      if (this.isSubstepsChanged) this.showAnnotationConfirmModal = true;
      else this.handleAnnotationClose();
    },

    handleAnnotationClose() {
      this.showAnnotationConfirmModal = false;
      this.showAnnotationModal = false;
      this.loading = true;
      this.updateVideo(this.selectedVideo.fileName, this.selectedVideo);
    },

    setMetaData(response) {
      if (typeof response === 'string') {
        response = response.replaceAll('Infinity', Number.MAX_SAFE_INTEGER);
        const parsedResponse = JSON.parse(response);
        this.setPrevMetaData(parsedResponse);
      } else {
        this.setPrevMetaData(response);
      }
    },

    // async accelerateVideo(video) {
    //   const payload = {
    //     queueName: 'ant-media',
    //     sqsMessage: {
    //         file_name: video.fileName,
    //         file_url: video.fileURL,
    //         task_id: video.id,
    //         method: "POST",
    //     },
    //   };
    //
    //   const [error, data] = await SQSServices.sendSQSMessage(payload);
    //   if (error) {
    //     console.log({ error });
    //     this.toast.error(error);
    //     return;
    //   }
    //   this.toast.success(data.Status);
    // },
    //
    // async decelerateVideo(video) {
    //   const payload = {
    //     queueName: 'ant-media',
    //     sqsMessage: {
    //       video_id: video.ant_media_id,
    //       task_id: video.id,
    //       method: "DELETE",
    //     },
    //   };
    //   const [error, data] = await SQSServices.sendSQSMessage(payload);
    //   if (error) {
    //     console.log({ error });
    //     this.toast.error(error);
    //     return;
    //   }
    //   this.toast.success(data.Status);
    // },

    async handleExportMetaFile(video) {
      this.selectedVideo = { ...video };
      const url = this.getPreMetaDataUrl();
      let metaData = await httpClient.getData(url);
      downloadFile(video.fileName, 'json', JSON.stringify(metaData));
    },

    async handleTrim(video) {
      this.showTrimModal = true;
      if (!video.fileURL) {
        const presignedUrl = await this.getVideoUrl(video);
        video = { ...video, fileURL: presignedUrl };
      }
      this.videoToTrim = { ...video };
    },

    handleLabelling({ data, isEmptyJson }) {
      this.showAnnotationConfirmModal = false;
      this.loading = true;
      this.showAnnotationModal = false;
      if (isEmptyJson) return;
      const { labelled, fileName } = data;
      const temp = [...this.video_list];
      const index = temp.findIndex((v) => v.fileName === fileName);
      temp[index].labelled = labelled;
      this.video_list = temp;
    },

    handleTrimModalClose() {
      this.updateVideo(this.videoToTrim.fileName, this.videoToTrim);
      this.videoToTrim = null;
      this.loading = true;
    },

    handleUploadModalClose() {
      setTimeout(this.$refs.vTour.nextStep, 500);
    },

    async handleDeleteVideo(video) {
      console.log('delete ->', video, this.video_list.length, this.currentPage);
      const res = await httpClient.delete(
        'organization/task_record/',
        video.id + '/'
      );
      if (res === 'error') {
        console.log('error occured while deleting a video');
        return;
      } else {
        this.video_list.length === 1
          ? this.handlePageChange({ current: this.currentPage - 1 })
          : this.fetchVideos(this.task_id);
      }
    },

    async updateTaskRecord(updatedData, videoToUpdate, alert = true) {
      this.updateVideo(videoToUpdate.fileName, updatedData);
      const [error, _] = await VideoService.updateTaskRecord(
        videoToUpdate.id,
        updatedData
      );
      if (error && alert) {
        this.toast.success('Error occured while updating the record!');
      }
      if (alert) this.toast.success('Record updated successfully!');
    },

    handlePageChangeImages(page) {
      this.currentPageForImage = page;
      this.loadImagesForAnnotation(this.task_id);
    },

    async loadImagesForAnnotation(taskId) {
      this.loadingImages = true;
      const res = await httpClient.getData(
        `organization/task/annotation_image_list/${taskId}/?page=${this.currentPageForImage}`,
        false
      );
      this.loadingImages = false;
      if (res === 'error') {
        this.toast.error('error in getting images');
        return;
      }
      this.imagesForAnnotation = res.results;
      this.totalImagesForAnnotation = res.count;
    },

    handleAddRule() {
      const newRuleObj = {
        rule: this.ruleInputValue,
        objects: this.objectList,
      };
      this.rulesList = [...this.rulesList, newRuleObj];
      console.log('newRule ->', this.rulesList);
      this.ruleInputValue = '';
      this.objectList = [];
    },

    handleAddObject(position) {
      const objList = [...this.objectList, undefined];
      this.objectList = objList;
    },

    handleSelectObjectValue(value, position) {
      const temp = [...this.objectList];
      temp[position] = value;
      this.objectList = [...temp];
    },

    async set3dCheck(taskId){
      const res = await httpClient.getData(`organization/task/is_3d/${taskId}/`)
      if (res === 'error') return;
      this.isTask3D = res?.is_3d
    }
  },
};
</script>
<style>
.objects-container {
  height: 50vh;
  margin-top: 1em;
  overflow: auto;
}

.ant-modal-footer {
  display: flex;
}

.bold {
  font-weight: 600;
}
</style>
