<template>
  <a-modal
    id="mark-objects-annotation-modal"
    v-model:visible="openObjectAnnotateDialog"
    centered
    closable
    width="80vw"
    :body-style="{ height: '80vh' }"
    :footer="null"
    destroy-on-close
    title="Object Annotation"
  >
    <object-annotation
      :current-image="currentImage"
      :image-obj="imageList[currentImageIndex]"
      :image-index="currentImageIndex"
      :image-path-in-bucket="requestedImagePath"
      :annotation-file-name="requestImageAnnotationName"
      :annotation-file-path="requestImageAnnotationPath"
      :image-id="requestedImageId"
      :total-results="totalResults"
      :current-page="currentImagePage"
      @closeAnnotationModal="handleCloseAnnotationModal"
      @changeImageAnnotationPath="changeImageAnnotationPath"
      @saveAnnotationTime="saveAnnotationTime"
      @nextImage="handleImageChange"
      @prevImage="handleImageChange"
      @cacheImage="cacheImage"
    />
  </a-modal>
  <a-card
    id="mark-objects-card"
    :body-style="{ height: '59vh', overflowY: 'auto' }"
    :loading="loadingImages"
  >
    <template #title>
      <div class="d-flex align-items-center">
        <a-typography-title :level="5" class="my-0">
          Total Objects: &nbsp;
          <a-tag id="annotation-objects-count" color="blue">
            {{ totalResults }}
          </a-tag>
        </a-typography-title>
      </div>
    </template>
    <template #extra>
      <a-button
        id="refresh-images-btn"
        type="default"
        size="small"
        class="mr-2"
        @click="reloadImages"
      >
        <template #icon>
          <reload-outlined />
        </template>
      </a-button>
    </template>
    <a-list
      :grid="{
        gutter: 32,
        xs: 1,
        sm: 1,
        md: 1,
        lg: 2,
        xl: 3,
        xxl: 3,
        xxxl: 4,
      }"
      :data-source="imageList"
    >
      <template #renderItem="{ item,index }">
        <a-list-item>
          <a-badge-ribbon
            v-if="item.path_to_annotations"
            color="#87d068"
            text="Annotated"
          >
            <image-card
              :item="item"
              :index="index"
              :get-file-name="getFileName"
              :open-annotation-modal="openAnnotationModal"
              :isAnnotated="false"
            />
          </a-badge-ribbon>
          <image-card
            v-else
            :item="item"
            :index="index"
            :get-file-name="getFileName"
            :open-annotation-modal="openAnnotationModal"
            :isAnnotated="false"
          />
        </a-list-item>
      </template>
    </a-list>
  </a-card>
  <a-pagination
    id="mark-objects-pagination"
    :total="totalResults"
    page-size="30"
    :show-size-changer="false"
    class="mt-2"
    @change="handlePageChange"
  />
</template>
<script>
import { ReloadOutlined } from '@ant-design/icons-vue';
import ObjectAnnotation from './ObjectAnnotation/ObjectAnnotation.vue';
import { mapGetters } from 'vuex';
import httpClient from '../../../../service/httpClient';
import ImageCard from './ImageCard.vue';

export default {
  name: 'AnnotationList',

  components: {
    ObjectAnnotation,
    ReloadOutlined,
    ImageCard,
  },

  props: [
    'imageList',
    'totalResults',
    'taskId',
    'currentImageIndex',
    'currentImagePage',
    'loadingImages',
  ],

  emits: [
    'changeImagesPage',
    'changeImageIndex',
    'changeImageAnnotationPath',
    'cacheImage',
    'preLoadUrls',
    'loadAnnotationImages',
  ],

  data() {
    return {
      openObjectAnnotateDialog: false,
      requestedImagePath: '',
      requestedImageId: '',
      requestImageAnnotationPath: '',
      requestImageAnnotationName: '',
      loadingImage: false,
      startTime: '',
      end_time: '',
      user: localStorage.getItem('id'),
      currentImage: null,
    };
  },

  computed: {
    ...mapGetters(['organization', 'taskName']),
  },

  watch: {
    currentImageIndex(index) {
      if (index < 0 || index >= this.totalResults) return;
      this.openImageForAnnotation(this.imageList[index]);
    },
  },

  methods: {
    cacheImage(img) {
      this.$emit('cacheImage', img);
    },

    handleImageChange(newIndex) {
      this.$emit('changeImageIndex', this.currentImageIndex + newIndex);
      const imageToBeLabelled = this.imageList[
        this.currentImageIndex + newIndex
      ];
      this.currentImage = this.getFileName(imageToBeLabelled?.path_to_image);
    },

    changeImageAnnotationPath(updatedAnnotationRecord) {
      this.$emit('changeImageAnnotationPath', updatedAnnotationRecord);
    },

    openAnnotationModal(index, imageObject) {
      this.currentImage = this.getFileName(imageObject.path_to_image);
      this.openObjectAnnotateDialog = true;
      this.$emit('changeImageIndex', index);
    },

    async openImageForAnnotation(image) {
      console.log('opened for annotation');
      this.requestedImagePath = image.path_to_image;
      this.requestedImageId = image.id;
      this.requestImageAnnotationName = this.getFileName(
        image.path_to_annotations
      );
      this.requestImageAnnotationPath = image.path_to_annotations;
      this.openObjectAnnotateDialog = true;
      this.startTime = new Date().toISOString();
    },

    getFileName(name) {
      if (name) return name.split('/').at(-1);
    },

    handlePageChange(page) {
      this.$emit('changeImagesPage', page);
    },

    getPayload() {
      return {
        start_time: this.startTime,
        end_time: new Date().toISOString(),
        task_type: 'image',
        user: this.user,
        task: this.taskId,
      };
    },

    async saveAnnotationTime() {
      const payload = this.getPayload();
      await httpClient.post(
        'cloud_source_labeling/create_anotation_session/',
        payload,
        false,
        false,
        false
      );
    },

    async reloadImages() {
      this.$emit('loadAnnotationImages', this.taskId);
    },

    handleCloseAnnotationModal() {
      const currentImg = this.imageList[this.currentImageIndex];
      this.requestImageAnnotationPath = currentImg.path_to_annotations;
      this.openObjectAnnotateDialog = false;
    },
  },
};
</script>

<style scoped>
#components-grid-demo-playground pre {
  background: #f9f9f9;
  border-radius: 6px;
  font-size: 13px;
  padding: 8px 16px;
}
</style>
