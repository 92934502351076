<template>
  <label-data />
</template>
<script>
import LabelData from './LabelData.vue';

export default {
  components: {
    LabelData,
  },
};
</script>
