<template>
  <v-contextmenu ref="contextmenu">
    <v-contextmenu-item id="mo-delete-object" @click="deleteObject">
      Delete
    </v-contextmenu-item>
  </v-contextmenu>
  <a-row
    class="h-100 d-flex justify-content-around align-items-center"
    :gutter="[24, 0]"
  >
    <a-col span="16" class="h-100 d-flex flex-column">
      <a-card
        :title="currentImage"
        hoverable
        :body-style="{
          padding: '1em',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }"
        size="small"
        class="annotation-image-card d-flex flex-column flex-grow-1"
      >
        <a-spin
          v-if="imageLoading"
          size="large"
          tip="Loading Image..."
          :indicator="indicator"
          class="m-auto"
        />

        <template #extra>
          <a-button
            id="mark-object-previous-btn"
            type="primary"
            class="mr-2"
            :disabled="imageIndex <= 0"
            @click="$emit('prevImage', -1)"
          >
            Previous
          </a-button>

          <a-button
            id="mark-object-next-btn"
            type="primary"
            :disabled="nextImageButtonDisabled"
            @click="$emit('nextImage', 1)"
          >
            Next
          </a-button>
        </template>

        <template v-if="!imageLoading" #cover>
          <app-loader
            v-if="!isObjectFetched"
            position="absolute"
            color="#1890ff"
          />

          <v-stage
            ref="stage"
            v-contextmenu:contextmenu
            :config="stageSize"
            @mousedown="handleStageMouseDown"
            @touchstart="handleStageMouseDown"
          >
            <v-layer id="region-define-canvas-layer" ref="layer">
              <v-image
                id="region-define-canvas-image"
                ref="image"
                :config="imageConfig"
              />
              <v-group
                v-for="(rect, index) in groups.rectangles"
                :key="rect.name + 1"
                :config="{ draggable: isDraggable(rect) }"
              >
                <v-rect
                  :key="rect.name"
                  :config="rect"
                  @transformend="handleTransform"
                />
                <v-text :key="index" :config="groups.labels[index]" />
              </v-group>
              <v-transformer ref="transformer" />
            </v-layer>
          </v-stage>
        </template>

        <template #actions>
          <div class="d-flex">
            <a-button
              id="mo-save-annotation-btn"
              class="ml-auto mr-3"
              type="primary"
              :loading="isSavingAnnotation"
              @click="saveAnnotation"
            >
              Save
            </a-button>
          </div>
        </template>
      </a-card>
    </a-col>

    <a-col span="8" class="h-100 d-flex flex-column" style="gap:5px;">
      <a-list
        id="mark-objects-annotate-obj-list"
        bordered
        class="objects-list"
        size="small"
        :loading="{ spinning: !isObjectFetched, indicator }"
        :data-source="getNonStaticObjects"
        item-layout="horizontal"
      >
        <template #header>
          <span>Objects</span>
        </template>
        <template #renderItem="{ item, index }">
          <a-list-item
            :id="'mo-annotate-obj-' + index"
            :class="{ 'disable-click': disabledObjects[item.id] }"
            class="pl-2  clickable object-list-item"
            @click="onObjectSelect(item)"
          >
            <a-list-item-meta>
              <template #title>
                <span :class="{ 'text-muted': disabledObjects[item.id] }">
                  {{ item.name }}
                </span>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
      <a-list
        id="mark-objects-annotate-region-list"
        bordered
        class="objects-list"
        size="small"
        :loading="{ spinning: !isObjectFetched, indicator }"
        :data-source="getStaticObjects"
        item-layout="horizontal"
      >
        <template #header>
          <span>Regions</span>
        </template>
        <template #renderItem="{ item, index }">
          <a-list-item
            :id="'mo-annotate-obj-' + index"
            :class="{ 'disable-click': disabledObjects[item.id] }"
            class="pl-2  clickable object-list-item"
            @click="onObjectSelect(item)"
          >
            <a-list-item-meta>
              <template #title>
                <span :class="{ 'text-muted': disabledObjects[item.id] }">
                  {{ item.name }}
                </span>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
    </a-col>
  </a-row>
</template>

<script>
import { parseStringCoordsToObj } from './helpers';
import { LoadingOutlined } from '@ant-design/icons-vue';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import * as OBJECT from './object';
import httpClient from '../../../../../service/httpClient';
import objectsMarkingMixin from '@/mixins/objectsMarking';
import { h } from 'vue';
import S3Service from 'src/services/s3.js';
import DetectorService from 'src/services/detector.js';
import AppLoader from 'src/components/core/Loading.vue';

export default {
  components: { AppLoader },
  mixins: [objectsMarkingMixin],
  inject: ['toast'],

  props: [
    'currentImage',
    'imagePathInBucket',
    'annotationFileName',
    'annotationFilePath',
    'imageId',
    'imageObj',
    'imageIndex',
    'totalResults',
    'currentPage',
  ],

  emits: [
    'changeImageAnnotationPath',
    'saveAnnotationTime',
    'nextImage',
    'prevImage',
    'cacheImage',
    'closeAnnotationModal',
  ],

  setup() {
    const indicator = h(LoadingOutlined, {
      style: {
        fontSize: '36px',
        margin: 'auto',
      },
      spin: true,
    });
    return {
      indicator,
    };
  },

  data() {
    return {
      imageHeight: 0,
      imageWidth: 0,
      selectedShapeIndex: -1,
      selectedShapeName: '',
      selectedShapeId: -1,
      imageToBeAnnotated: '',
      image: null,
      imageLoading: true,
      updatedStaticObjectId: new Set(),
      groups: {
        rectangles: {},
        labels: {},
      },
      objectList: [],
      isObjectFetched: false,
      imageDimensions: { width: 0, height: 0 },
      stageSize: {
        width: 640,
        height: 480,
      },
      disabledObjects: {},
      isSavingAnnotation: false,
    };
  },

  computed: {
    ...mapGetters(['organization', 'taskObjectList', 'getTaskId']),
    nextImageButtonDisabled() {
      const skip = (this.currentPage - 1) * 30;
      const recordIndex = this.imageIndex + 1;
      return recordIndex + skip >= this.totalResults || this.imageIndex >= 29;
    },

    getStaticObjects() {
      return this.objectList.filter((obj) => obj.is_static === true);
    },

    getNonStaticObjects() {
      return this.objectList.filter((obj) => !obj.is_static);
    },

    imageConfig() {
      return {
        image: this.image,
        name: 'currentImage',
        ...this.stageSize,
      };
    },
  },

  watch: {
    async imageObj(value) {
      if (!value) return;
      this.groups = {
        rectangles: {},
        labels: {},
      };
      this.isObjectFetched = false;
      this.imageLoading = true;
      await this.loadImage(value);
      this.imageLoading = false;
      await this.updateImageConfig();
      await this.renderExistingObjectList();
      this.isObjectFetched = true;
    },
  },

  async created() {
    this.isObjectFetched = false;
    this.imageLoading = true;
    await this.loadImage(this.imageObj);
    this.imageLoading = false;
    await this.updateImageConfig();
    await this.renderExistingObjectList();
    this.isObjectFetched = true;
  },

  methods: {
    ...mapActions(['setTaskObjectList']),

    isDraggable(object) {
      return OBJECT.isDraggable(object);
    },

    isAnnotated() {
      return this.annotationFilePath !== null;
    },

    toggleObjectSelection(object) {
      const { id, is_static } = object;
      if (is_static) {
        this.disabledObjects[id] = !this.disabledObjects[id];
        this.updatedStaticObjectId.add(id);
      }
    },
    updateGroup(name, rect, text) {
      this.groups.rectangles[name] = rect;
      this.groups.labels[name] = text;
    },

    updateImageConfig() {
      return new Promise(async (resolve) => {
        const canvas = await this.getCanvasElement();

        const { width, height } = canvas.getBoundingClientRect();

        this.imageDimensions = {
          width,
          height,
        };
        this.stageSize = {
          width,
          height,
        };
        resolve();
      });
    },

    getCanvasElement() {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          const canvas = document.getElementsByTagName('canvas')[0];
          if (canvas) {
            clearInterval(interval);
            resolve(canvas);
          }
        }, 1000);
      });
    },

    onObjectSelect(object) {
      const { rectangle, label } = this.createRectLabelGroup(object);
      this.updateGroup(rectangle.name, rectangle, label);
      this.toggleObjectSelection(object);
    },

    // get Image to Display
    async getImageUrlFromS3() {
      if (!this.imagePathInBucket) return;
      const obj = {
        bucket_name: this.organization + '-training',
        object_path: this.imagePathInBucket,
      };
      const { presigned_url } = await httpClient.post(
        'generic/generate_new_url/',
        obj,
        false,
        false,
        false
      );
      return presigned_url;
    },

    loadImage(imageObj) {
      return new Promise(async (resolve) => {
        if (!imageObj.loadedImage) {
          const img = new window.Image();
          img.src = await this.getImageUrlFromS3();
          img.onload = () => {
            this.image = img;
            this.image.width = img.naturalWidth;
            this.image.height = img.naturalHeight;
            this.$emit('cacheImage', img);
            resolve();
          };
        } else {
          const { loadedImage } = imageObj;
          this.image = loadedImage;
          this.image.width = loadedImage.width;
          this.image.height = loadedImage.height;
          resolve();
        }
      });
    },

    // for rendering existing objects
    getObjectListFromState() {
      return this.taskObjectList.map((o) => {
        return {
          ...o,
          cordinates_of_static_object: parseStringCoordsToObj(
            o.cordinates_of_static_object
          ),
        };
      });
    },

    async getObjectListFromS3() {
      const obj = {
        bucket_name: this.organization + '-training',
        object_path: this.annotationFilePath,
      };
      const res = await httpClient.post(
        'generic/generate_new_url/',
        obj,
        false,
        false,
        false
      );
      const { data } = await axios.get(res.presigned_url);
      return data.map(OBJECT.nestCoordinates);
    },

    setObjectSelectionState() {
      Object.assign(
        this.disabledObjects,
        ...this.objectList.map(OBJECT.isObjectDisabled)
      );
    },

    renderExistingObjectList() {
      return new Promise(async (resolve) => {
        let objectList = this.getObjectListFromState();
        let s3Objects = [];
        if (this.isAnnotated()) {
          s3Objects = await this.getObjectListFromS3();
          s3Objects = s3Objects.filter((o) => !o.is_static);
        }
        objectList.sort((a, b) =>
          a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })
        );
        this.objectList = [...objectList];
        const existingStaticObs = objectList.filter(OBJECT.hasCoordinates);
        this.groups = {
          ...this.createRectanglesFromExistingObjects([
            ...existingStaticObs,
            ...s3Objects,
          ]),
        };
        this.setObjectSelectionState();
        resolve();
      });
    },

    // functions for saving annotations
    updateObjectListState(staticObjects) {
      let temp = this.objectList.slice();
      temp.sort((a, b) => a.id - b.id);
      staticObjects.sort((a, b) => a.id - b.id);
      let staticObjectIndex = 0;
      for (
        let i = 0;
        i < temp.length &&
        staticObjects.length > 0 &&
        staticObjectIndex < staticObjects.length;
        i++
      ) {
        const obj1 = temp[i];
        const obj2 = staticObjects[staticObjectIndex];
        if (
          OBJECT.isSameObject(obj1, obj2) &&
          OBJECT.isObjectMoved(obj1, obj2)
        ) {
          Object.assign(obj1, obj2);
          staticObjectIndex++;
        }
        if (obj1.id > obj2.id) {
          staticObjectIndex++;
          i--;
        }
      }
      temp.forEach((obj) => {
        if (OBJECT.hasCoordinates(obj))
          obj.cordinates_of_static_object = JSON.stringify(
            obj.cordinates_of_static_object
          );
        else obj.cordinates_of_static_object = null;
      });
      this.objectList = [...temp];
      this.setTaskObjectList(temp.slice());
    },

    uploadAnnotationToS3(s3Objects) {
      return new Promise(async (resolve) => {
        const { file_path, file_name } = this.getAnnotationNamePath();
        const payload = this.getPayloadForUploading(
          s3Objects,
          file_path,
          file_name
        );

        const fileUploadResp = S3Service.uploadFile(payload, false);

        const imageAnnotationResp = DetectorService.updateImageAnnotation(
          { path_to_annotations: file_path },
          this.imageId,
          false
        );

        const [[error], [_error, data]] = await Promise.all([
          fileUploadResp,
          imageAnnotationResp,
        ]);

        if (!error && !_error) this.toast.success('Annotations Saved');
        else console.log({ error, _error });

        this.$emit('changeImageAnnotationPath', data);
        resolve();
      });
    },

    getAnnotationNamePath() {
      if (this.annotationFilePath)
        return {
          file_path: this.annotationFilePath,
          file_name: this.annotationFileName,
        };

      const pathSplit = this.imagePathInBucket.split('/');
      const imageFileName = pathSplit.at(-1);
      return {
        file_path: `${pathSplit
          .slice(0, 2)
          .join('/')}/labels/${imageFileName}.json`,
        file_name: `${imageFileName}.json`,
      };
    },

    getPayloadForUploading(s3Objects, file_path, file_name) {
      const fileAsJSON = JSON.stringify(s3Objects);
      const blob = new Blob([fileAsJSON], { type: 'application/json' });
      const data = new FormData();
      data.append('file', blob, file_name);
      data.append('file_path', file_path);
      data.append('bucket', this.organization + '-training');
      return data;
    },

    updateObjectCounts(s3Objects) {
      return new Promise(async (resolve) => {
        const payload = { annotation_data: s3Objects, task_id: this.getTaskId };
        await DetectorService.updateObjectsCount(payload, false);
        resolve();
      });
    },

    updateAllCoordinates(staticObjs) {
      const objectsTobeUpdated = staticObjs
        .filter((obj) => this.updatedStaticObjectId.has(obj.id))
        .map((obj) => ({
          ...obj,
          cordinates_of_static_object: JSON.stringify(
            obj.cordinates_of_static_object
          ),
        }));
      DetectorService.updateObjectsCoordinates(objectsTobeUpdated, false);
    },

    async saveAnnotation() {
      this.isSavingAnnotation = true;
      let newAnnotations = this.getAnnotations();
      if (newAnnotations.length === 0) {
        this.toast.error('No Annotations to save');
        return;
      }
      let staticObjects = newAnnotations
        .filter(OBJECT.isObjectStatic)
        .map(OBJECT.nestCoordinates);
      newAnnotations = newAnnotations.map(OBJECT.removeCoordinates);
      await this.uploadAnnotationToS3(newAnnotations);
      await this.updateObjectCounts(newAnnotations);
      this.updateAllCoordinates(staticObjects);
      this.updateObjectListState(staticObjects);
      this.$emit('saveAnnotationTime');
      this.isSavingAnnotation = false;
      this.$emit('closeAnnotationModal');
    },

    // for delete objects
    setObjectPropsToNull(object) {
      const [obj] = this.objectList.filter((o) => o.id === object.id);
      obj.cordinates_of_static_object = null;
    },

    deleteObject() {
      this.toggleObjectSelection(
        this.groups.rectangles[this.selectedShapeName]
      );
      this.setObjectPropsToNull(this.groups.rectangles[this.selectedShapeName]);
      delete this.groups.rectangles[this.selectedShapeName];
      delete this.groups.labels[this.selectedShapeName];
      this.selectedShapeName = '';
      this.updateTransformer();
    },

    // set Transformer when click on stage
    handleStageMouseDown(e) {
      if (!e.target.hasOwnProperty('parent')) {
        return;
      }

      if (e.target === e.target.getStage()) {
        this.selectedShapeName = '';
        this.updateTransformer();
        return;
      }

      const clickedOnTransformer =
        e.target.getParent().className === 'Transformer';
      if (clickedOnTransformer) {
        return;
      }

      const name = e.target.name();
      const rect = this.groups.rectangles[name];
      if (rect) {
        this.selectedShapeName = name;
      } else {
        this.selectedShapeName = '';
      }
      this.updateTransformer();
    },
  },
};
</script>
<style>
.annotation-image-card > .ant-card-cover {
  padding: 1em !important;
  flex-grow: 1;
  display: flex !important;
}

.annotation-image-card > .ant-card-cover > div {
  display: flex !important;
}

.annotation-image-card > .ant-card-cover > div > .konvajs-content {
  width: unset !important;
  height: unset !important;
  flex-grow: 1 !important;
  display: flex;
}

.annotation-image-card > .ant-card-cover > div > .konvajs-content > canvas {
  border-radius: 5px;
  flex-grow: 1;
  width: 100% !important;
  height: 100% !important;
  border: 1.5px solid #d9d9d9 !important;
}

.objects-list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.objects-list > .ant-list-header {
  background: lightgray !important;
  font-weight: 600 !important;
}

.objects-list > .ant-spin-nested-loading {
  flex-grow: 1;
  height: 1px;
  overflow-y: auto;
}
</style>
